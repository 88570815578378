import React from 'react'
import { BsTwitter, BsInstagram } from 'react-icons/bs';
import { FaFacebookF } from 'react-icons/fa';
import { AiFillLinkedin, AiFillGithub } from 'react-icons/ai';
import { FiDownload } from 'react-icons/fi';

const SocialMedia = () => {
    return (
        <div className='app__social'>
            <a href='https://www.linkedin.com/in/mayank-dahiya-aa90a2153/' target="_blank" rel="noopener noreferrer">
                <div>
                    <AiFillLinkedin/>
                </div>
            </a>
            <a href='https://github.com/MayankDahiya1' target="_blank" rel="noopener noreferrer">
                <div>
                    <AiFillGithub/>
                </div>
            </a>
            {/*<a href='https://www.facebook.com/profile.php?id=100004208986496' target="_blank" rel="noopener noreferrer">*/}
            {/*    <div>*/}
            {/*        <FaFacebookF />*/}
            {/*    </div>*/}
            {/*</a>*/}
            {/*<a href='https://www.instagram.com/_mrdahiya_/' target="_blank" rel="noopener noreferrer">*/}
            {/*    <div>*/}
            {/*        <BsInstagram />*/}
            {/*    </div>*/}
            {/*</a>*/}
            <a href='/MayankResume.pdf' download target="_blank" rel="noopener noreferrer">
                <div>
                    <FiDownload/>
                </div>
            </a>
        </div>
    )
}

export default SocialMedia;
